import { LiffProvider } from 'context/Liff';
import AppWrapper from 'AppWrapper';

const LIFFID = '1656716575-p8BEzWLR'; // main
// const LIFFID = '1656708364-BrMpjqNz'; // beta
// const LIFFID = '1656691395-L73dr9A8'; // dev
// const LIFFID = '1656708372-awDzMQOM'; // dev-dofi
// const LIFFID = '1656726508-GmZE9eVa'; // dev-rich

function App() {
  return (
    <LiffProvider liffId={LIFFID}>
      <AppWrapper />
    </LiffProvider>
  );
}

export default App; 
